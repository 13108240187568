<template>
  <div>
    <div class="reservation-widget-overlay" v-if="selectedEvent" @click.prevent="selectedEvent = null; selectedActivity = null; selectedTime = null; formIsSent = null; isLoading = false; formIsSent = false;"></div>
    <div class="reservation-widget-wrapper" v-if="selectedEvent" @click.prevent="selectedEvent = null; selectedActivity = null; selectedTime = null; formIsSent = null; isLoading = false; formIsSent = false;">
      <div class="reservation-widget-box" @click.stop>
        <div class="content-activities" v-if="!selectedActivity">
          <div class="select-activity">
            <h3 class="event-card-title medium-title">Choisissez votre cours</h3>
            <div class="activities-listing">
              <a href="#" v-for="activity in selectedEvent.dates.find(i => i.slug === selectedDate).activities" :key="activity.name" @click.prevent="setSelectedActivity(activity.slug)">
                <article class="activity-item">
                  <img :src="activity.img" :alt="selectedEvent.slug">
                  <div class="content">
                    <h4 class="small-title">{{ activity['name-' + $route.meta.lang] }}</h4>
                    <p class="regular-text">{{ activity['description-' + $route.meta.lang] }}</p>
                  </div>
                  <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25"/>
                    <path d="M22.7564 18.024C22.3637 18.0179 21.984 18.1685 21.6775 18.4608C21.0798 19.0617 21.0643 20.0636 21.6434 20.6827L26.1438 25.5114L21.4824 30.1964C20.8847 30.7973 20.8693 31.7991 21.4483 32.4183C22.0273 33.0375 22.9937 33.0526 23.5914 32.4517L29.3418 26.6727C29.6329 26.3801 29.7898 25.9913 29.7964 25.5683C29.8028 25.1459 29.6426 24.7521 29.376 24.4502L23.8209 18.4941C23.5235 18.1923 23.1339 18.0297 22.7565 18.0238L22.7564 18.024Z" fill="white"/>
                  </svg>
                </article>
              </a>
            </div>
          </div>

          <a href="#" @click.prevent="selectedEvent = null; selectedActivity = null; selectedTime = null; formIsSent = false;" class="close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#001489" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </a>
        </div>
        <div class="content-grid" v-if="selectedActivity">
          <div class="preview">
            <article class="event-card">
              <img :src="selectedEvent.dates.find(i => i.slug === selectedDate).activities.find(d => d.slug === selectedActivity).img" :alt="selectedEvent.slug">
              <div class="content">
                <h3 class="event-card-title regular-title"><span v-html="selectedEvent.title"></span></h3>
                <h3 class="event-card-title small-title" v-if="selectedActivity"><span v-html="selectedEvent.dates.find(i => i.slug === selectedDate).activities.find(d => d.slug === selectedActivity)['name-' + $route.meta.lang]"></span></h3>
                <div class="details small-title">
                  <p class="event-card-price">{{ $t('Prix') }} : {{ $t(selectedEvent.price) }}</p>
                  <p class="event-card-date" v-if="selectedDate">{{ selectedEvent.dates.find(i => i.slug === selectedDate)['value-' + $route.meta.lang] }}</p>
                </div>
              </div>
            </article>
          </div>

          <div class="form" v-if="!selectedTime && !isLoading">
            <h4 class="regular-title">{{ $t('Choisissez votre heure') }}</h4>
            <div class="hours">
              <a
                :class="{ 'is-active': hour.ref === selectedTime }"
                href="#"
                v-for="hour in selectedEvent.dates.find(i => i.slug === selectedDate).activities.find(d => d.slug === selectedActivity).hoursList"
                :title="hour.label"
                :key="hour.ref"
                @click.prevent="setSelectedTime(hour.ref)">
                  {{ hour['label-' + $route.meta.lang] }}
                </a>
            </div>
          </div>

          <div class="form" v-else-if="selectedTime && !formIsSent && !isLoading">

            <h4 class="regular-title" v-if="totalReservations < totalPlaces"><strong>{{ selectedTime }}</strong> <span><span>{{ totalPlacesLeft }}</span> {{ $t('places disponibles') }}</span></h4>

            <form action="" method="get" accept-charset="utf-8" @submit="sendForm" v-if="totalReservations < totalPlaces">
              <input v-model="reservation.client.firstname" type="text" name="Prénom" value="" :placeholder="$t('Prénom')" required>
              <input v-model="reservation.client.lastname" type="text" name="Nom" value="" :placeholder="$t('Nom')" required>
              <input v-model="reservation.client.email" type="email" name="Courriel" value="" :placeholder="$t('Courriel')" required>
              <select name="Sexe" required :placeholder="$t('Sexe')" v-model="reservation.client.gender">
                <option value="" disabled selected >{{ $t('Sexe') }}</option>
                <option value="Homme">{{ $t('Homme') }}</option>
                <option value="Femme">{{ $t('Femme') }}</option>
                <option value="Autre">{{ $t('Autre') }}</option>
                <option value="Je refuse de répondre">{{ $t('Je refuse de répondre') }}</option>
              </select>

              <!-- <div class="input-faker">
                <span>Genre</span>

                <div class="radio-btns">
                  <label for="Homme">Homme</label>
                  <input id="Homme" v-model="reservation.client.gender" type="radio" name="genre" value="Homme">
                </div>
                <div class="radio-btns">
                  <label for="Femme">Femme</label>
                  <input id="Femme" v-model="reservation.client.gender" type="radio" name="genre" value="Femme">
                </div>
                <div class="radio-btns">
                  <label for="Autres">Autres</label>
                  <input id="Autres" v-model="reservation.client.gender" type="radio" name="genre" value="Autres">
                </div>
              </div> -->
              <div class="checkbox-label">
                <input id="accept" v-model="reservation.client.subscription" type="checkbox" name="accept" value="accept">
                <label for="accept">

                  {{ $t('Inscrivez-moi! J’aimerais recevoir des informations exclusives et personnalisées sur les produits, les promotions ou les concours Coppertone, par courriel ou via la publicité en ligne. Si vous ne souhaitez plus recevoir nos courriels, vous pouvez vous désabonner à tout moment. Pour plus d’informations sur notre politique de confidentialité veuillez cliquer') }}

                  <a v-if="$route.meta.lang === 'fr'" href="https://firebasestorage.googleapis.com/v0/b/coppertone-f40e1.appspot.com/o/Privacy%20Policy_Gym%20au%20Soleil_FR.pdf?alt=media&token=a3c0bce4-031f-46e6-84e9-4bf9808ef858" target="_blank" rel="noopener noreferrer">{{ $t('ici') }}</a>
                  <a v-if="$route.meta.lang === 'en'" href="https://firebasestorage.googleapis.com/v0/b/coppertone-f40e1.appspot.com/o/Privacy%20Policy_Gym%20au%20Soleil_EN.pdf?alt=media&token=aa5c8912-1477-4d93-8e82-726a5baad55c" target="_blank" rel="noopener noreferrer">{{ $t('ici') }}</a> *
                </label>
              </div>
              <button class="site-btn">
                {{ $t('Réservez votre place') }}
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <polyline points="9 6 15 12 9 18" />
                </svg>
              </button>
            </form>
            <div v-else>
              <h2 class="medium-title" style="text-align: center; padding: 5vw;">
                <span><span>{{ $t('Aucune place disponible') }}</span></span>
              </h2>
              <a href="#" class="site-btn" @click.prevent="selectedTime = null">{{ $t('Retour') }}</a>
            </div>
          </div>

          <!-- Success -->
          <div class="form is-success" v-else-if="selectedTime && formIsSent && !isLoading">
            <svg viewBox="0 0 100 100" class="animate">
              <filter id="dropshadow" height="100%">
                <feGaussianBlur
                  in="SourceAlpha"
                  stdDeviation="3"
                  result="blur"
                />
                <feFlood
                  flood-color="rgba(76, 175, 80, 1)"
                  flood-opacity="0.5"
                  result="color"
                />
                <feComposite
                  in="color"
                  in2="blur"
                  operator="in"
                  result="blur"
                />
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>

              <circle
                cx="50"
                cy="50"
                r="46.5"
                fill="none"
                stroke-width="5"
              />

              <path
                d="M67,93 A46.5,46.5 0,1,0 7,32 L43,67 L88,19"
                fill="none"
                stroke-width="5"
                stroke-linecap="round"
                stroke-dasharray="80 1000"
                stroke-dashoffset="-220"
                style="filter:url(#dropshadow)"
              />
            </svg>
            <h4 class="regular-title">{{ $t('Merci') }}</h4>
            <p class="regular-text" v-html="$t('Vous recevrez sous peu un courriel confirmant votre réservation.<br>Bon entraînement au soleil!')"></p>
            <a href="https://www.coppertone.ca/" target="_blank" rel="noopener noreferrer" class="site-btn">
              {{ $t('poursuivez sur coppertone.ca') }}
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
          </div>

          <!-- Loader -->
          <div class="form is-loader" v-else>
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                  <animateTransform
                     attributeName="transform"
                     attributeType="XML"
                     type="rotate"
                     dur="1s"
                     from="0 50 50"
                     to="360 50 50"
                     repeatCount="indefinite" />
              </path>
            </svg>
          </div>

          <a href="#" @click.prevent="selectedEvent = null; selectedActivity = null; selectedTime = null; formIsSent = false;" class="close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#001489" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReservationsDB from '@/firebase/reservations-db'

export default {
  data() {
    return {
      selectedEvent: null,
      selectedDate: null,
      selectedActivity: null,
      selectedTime: null,
      formIsSent: false,
      isLoading: false,
      totalReservations: 0,
      reservation: {
        event: '',
        date: '',
        activity: '',
        time: '',
        client: {
          firstname: '',
          lastname: '',
          email: '',
          gender: '',
          subscription: false,
          lang: 'fr'
        }
      }
    }
  },
  methods: {
    async sendForm(e) {
      e.preventDefault()

      if (this.totalReservations >= this.totalPlaces) {
        this.totalReservations = this.totalPlaces
        return
      }

      const reservationObj = { ...this.reservation }
      reservationObj.event = this.selectedEvent.slug
      reservationObj.date = this.selectedDate
      reservationObj.activity = this.selectedActivity
      reservationObj.time = this.selectedTime
      reservationObj.viewFullDetails = {
        title: this.selectedEvent.title.replace('<br>', ''),
        address: this.selectedEvent.address.replace('<br>', '')
      }
      reservationObj.client.lang = this.$route.meta.lang

      const reservationId = `${reservationObj.event}+${reservationObj.date}+${reservationObj.activity}+${this.reservation.client.email.replace('@', '')}`

      // console.log({ reservationObj, reservationId })

      this.isLoading = true

      const reservationsDb = new ReservationsDB()
      await reservationsDb.create(reservationObj, reservationId)

      this.formIsSent = true
      this.isLoading = false
    },
    setSelectedEvent(data) {
      this.selectedEvent = data.event
      this.selectedDate = data.date
      this.fakeLoad()
    },
    setSelectedActivity(name) {
      this.selectedActivity = name
      this.fakeLoad()
    },
    async setSelectedTime(time) {
      this.isLoading = true
      this.selectedTime = time

      const reservationsDb = new ReservationsDB()
      const totalReservations = await reservationsDb.readAll([
        ['event', '==', this.selectedEvent.slug],
        ['activity', '==', this.selectedActivity],
        ['date', '==', this.selectedDate],
        ['time', '==', this.selectedTime]
      ])

      this.totalReservations = totalReservations.length
      this.isLoading = false
    },
    fakeLoad() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false;
      }, 755)
    }
  },
  computed: {
    totalPlaces() {
      if (!this.selectedEvent || !this.selectedDate || !this.selectedActivity || !this.selectedTime) {
        return 8
      }

      return this.selectedEvent.dates.find(i => i.slug === this.selectedDate).activities.find(d => d.slug === this.selectedActivity).hoursList.find(h => h.ref === this.selectedTime).places
    },
    totalPlacesLeft() {
      if (!this.totalPlaces || !this.totalReservations) {
        return this.totalPlaces
      }

      return this.totalPlaces - this.totalReservations
    }
  },
  mounted() {
    this.$eventHub.on('ReservationWidget.setSelectedEvent', (data) => {
      this.setSelectedEvent(data)
    })
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

.reservation-widget-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

  background: rgba(#000, 0.70);
}

.reservation-widget-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.reservation-widget-box {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) scale(1);

  z-index: 105;

  padding: 0 0;
  width: 100%;
  max-width: 60vw;
  min-width: 700px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 780px) {
    max-width: 93vw;
    max-height: 93vh;
    min-width: inherit;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
  }

  .content-grid {
    position: relative;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 780px) {
      flex-direction: column;
    }

    .preview {
      width: 40%;
      padding: 35px;
      border-right: 1px solid #EBEBEB;

      @media (max-width: 780px) {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #EBEBEB;

        .event-card {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            max-width: 175px;
            margin-right: 5%;
          }
        }
      }

      @media (max-width: 600px) {
        .event-card {
          margin: 0 auto;
          width: 100%;
          flex-direction: column;

          img {
            margin: 0 auto;
            max-width: 100%;
          }

          .content {
            margin: 0 auto;
            width: 100%;
          }
        }
      }

      .event-card-title {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .form {
      width: calc(100% - 40%);
      padding: 35px;

      @media (max-width: 780px) {
        width: 100%;
      }

      svg.animate {
        width: 100px;
        margin: 0 auto;
        margin-bottom: 25px;

        circle {
          stroke: rgba($colorAccent1, 0.47567);
        }

        path {
          stroke: $colorAccent1;
        }
      }

      .regular-title {
        margin-bottom: 15px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 14px;
        }
      }

      .hours {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        flex-wrap: wrap;

        a {
          width: calc(97% / 2);

          padding: 13px 20px 10px 20px;
          margin: 6px 0;

          border-radius: 3px;

          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 1;

          border: 1px solid $colorAccent1;
          background: #fff;
          color: $colorAccent1;

          text-align: center;

          transition: all 0.45s ease 0s;

          &:hover,
          &.is-active {
            background: $colorAccent1;
            color: #fff;
          }
        }
      }

      &.is-success {
        padding: 75px;
        text-align: center;

        .regular-title {
          display: block;
          width: 100%;
          text-align: center;

          font-size: 30px;
        }

        .regular-text {
          line-height: 1.72;
          margin-bottom: 25px;
        }
      }
    }

    .form.is-loader {
      position: relative;
      height: auto;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;

        path {
          fill: $colorAccent1;
        }
      }
    }
  }

  .content-activities {
    position: relative;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 35px;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .select-activity {
      background: #fff;

      .medium-title {
        width: 100%;
        text-align: center;
        padding: 20px;
      }

      .activities-listing {
        color: $colorAccent1;

        a {
          color: $colorAccent1;
        }

        .activity-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;
          max-width: 85%;
          margin: 0 auto;
          margin-bottom: 25px;

          &:hover {
            svg {
              fill: $colorAccent2;
            }
          }

          @media (max-width: 780px) {
            flex-direction: column;
            text-align: center;
            border-bottom: 1px solid rgba(#000, 0.123);
            padding-bottom: 15px;
          }

          img {
            max-width: 200px;

            @media (max-width: 780px) {
              max-width: 145px;
            }
          }

          .content {
            width: 100%;
            padding: 25px;

            .small-title {
              text-transform: uppercase;
              margin-bottom: 15px;
            }

            .regular-text {
              font-weight: 600;
              line-height: 1.54;
            }
          }

          svg {
            fill: $colorAccent1;
            min-width: 35px;
            transition: all 0.45s ease 0s;

            @media (max-width: 780px) {
              min-width: 35px;
            }
          }
        }
      }
    }
  }
}
</style>
