<template>
  <nav class="nav-links fluid-container">
    <div class="logo"><img src="@/assets/img/logo-coppertone.svg" alt="Coppertone"></div>
    <div class="links">
      <a href="/" :class="{ 'is-active': $route.meta.lang === 'fr' }"><span class="desktop-only">Français</span><span class="mobile-only">Fr</span></a>
      <a href="/en" :class="{ 'is-active': $route.meta.lang === 'en' }"><span class="desktop-only">English</span><span class="mobile-only">En</span></a>
    </div>
    <div class="logo logo-energie"><img class="energie-c" src="@/assets/img/logo-energie-cardio.svg" alt="Energie Cardio"></div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;

  .logo {
    min-width: 145px;

    &.logo-energie {
      text-align: right;
    }

    img {
      display: inline-block;
      width: 100%;

      &.energie-c {
        position: relative;
        top: 3px;
        width: 105px;
      }
    }

    @media (max-width: 780px) {
      min-width: 100px;

      img {
        &.energie-c {
          width: 70px;
        }
      }
    }
  }

  .links {
    width: 100%;
    margin: 0 auto;

    padding: 30px 0;
    text-align: center;

    a {
      text-transform: uppercase;
      padding: 0 25px;
      color: $colorAccent1;
      font-size: 14px;

      @media (max-width: 780px) {
        padding: 0 10px;
      }

      &.is-active {
        color: $colorAccent2;
      }
    }
  }
}
</style>
