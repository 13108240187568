<template>
  <div class="page-wrapper">

    <section class="hero fluid-container">
      <div class="hero-container">
        <img class="logo-gym" src="@/assets/img/gym-soleil.svg" alt="Gym au Soleil">
        <h1 class="hero-title big-title">
          Coppertone <sup class="sup">&reg;</sup>
        </h1>
        <h2 class="hero-subtitle medium-title">
          {{ $t('PROFITEZ DU SOLEIL PENDANT VOTRE ENTRAINEMENT') }} <br> {{ $t('GRACE A COPPERTONE') }} <sup class="sup">&reg;</sup> {{ $t('ET ÉNERGIE CARDIO') }}
        </h2>

        <div class="events-wrapper">
          <article class="event-card" v-for="event in eventsList" :key="event.id" :class="{ 'is-disable': event.id === 1 }">
            <img :src="event.img" :alt="event.slug">
            <div class="content">
              <h3 class="event-card-title regular-title"><span v-html="event.title"></span></h3>
              <p class="event-card-date" v-html="event.address"></p>
              <a class="address" :href="'https://www.google.ca/maps/place/' + event.google" target="_blank" rel="noopener noreferrer">
                <svg width="24" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4994 19.0387C10.5378 19.0387 8.13541 16.6115 8.13541 13.6151C8.13541 10.6187 10.5376 8.1897 13.4994 8.1897C16.4627 8.1897 18.8652 10.6186 18.8652 13.6151C18.8652 16.6115 16.4629 19.0387 13.4994 19.0387ZM26.2482 9.08501C26.0114 8.40729 25.7207 7.74826 25.3798 7.11811C25.0387 6.48626 24.649 5.88154 24.2139 5.31088C23.7789 4.74186 23.3001 4.2068 22.7827 3.71253C22.2636 3.21657 21.7059 2.76135 21.1162 2.3537C20.5216 1.94265 19.8933 1.58087 19.2415 1.27171C18.5796 0.959179 17.8925 0.700999 17.1887 0.502283C16.468 0.296757 15.7306 0.155777 14.9864 0.0776468C14.1044 -0.0157717 13.204 -0.0242683 12.3204 0.0487711C11.5728 0.109917 10.8304 0.235612 10.1047 0.422454C9.39576 0.604199 8.70364 0.84709 8.03336 1.14605C7.37487 1.4399 6.73986 1.78642 6.13676 2.18386C5.53873 2.57622 4.97261 3.01786 4.44176 3.50191C3.91594 3.98261 3.42542 4.50745 2.9786 5.06629C2.53174 5.62339 2.13024 6.21789 1.77412 6.83956C1.41966 7.46463 1.11223 8.11687 0.858585 8.7912C0.821628 8.88802 0.78635 8.98654 0.752754 9.08505C-0.0367737 11.3407 -0.241736 13.8442 0.312641 16.3889C1.61621 22.3899 6.5217 27.0474 9.51484 32.2279C9.78027 32.6865 12.2463 36.6867 13.14 38.7486V38.7503C13.1971 38.8964 13.3366 39 13.4995 39C13.6642 39 13.8036 38.8964 13.8607 38.7503V38.7486C14.7544 36.6866 17.2205 32.6867 17.4859 32.2279C20.4794 27.0474 25.3846 22.3899 26.6881 16.3889C27.2408 13.8444 27.0375 11.3409 26.248 9.08505" fill="#001489"/>
                </svg>
              </a>
              <div class="details small-title">
                <p class="event-card-price">{{ $t('Prix') }} : {{ $t(event.price) }}</p>
              </div>
              
              <!-- <div v-if="event.id !== 1">
                <a v-for="date in event.dates" :key="date.value" href="#" class="site-btn is-outline" @click.prevent="setSelectedEvent(event, date.slug)">
                  {{ $t('Réservez') }} - {{ date['label-' + $route.meta.lang] }}
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <polyline points="9 6 15 12 9 18" />
                  </svg>
                </a>
              </div> -->

            </div>
          </article>
        </div>
      </div>
    </section>

    <footer class="footer fluid-container">
      <img class="logo" src="/img/logo-vibrant.webp" alt="Vibfant Marketing">
      <p>Copyright © 2021 VIBRANT Marketing.</p>
    </footer>

    <ReservationWidget />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReservationWidget from '@/components/ReservationWidget'
import { eventsList } from '@/misc/content'

export default {
  head() {
    return {
      title: {
        inner: 'Gym au soleil Coppertone'
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Gym au soleil Coppertone` },
        {
          name: 'description',
          content: `Entraînez-vous et profitez du soleil grâce à Coppertone`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Gym au soleil Coppertone` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Entraînez-vous et profitez du soleil grâce à Coppertone`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Gym au soleil Coppertone` },
        { property: 'og:site_name', content: `Gym au soleil Coppertone` },
        {
          property: 'og:description',
          content: `Entraînez-vous et profitez du soleil grâce à Coppertone`
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/coppertone-f40e1.appspot.com/o/email-hero.jpg?alt=media&token=a4c19d6b-9510-4b35-a6b8-be3430bc66d4'
        },
        {
          name: 'twitter:image',
          content: 'https://firebasestorage.googleapis.com/v0/b/coppertone-f40e1.appspot.com/o/email-hero.jpg?alt=media&token=a4c19d6b-9510-4b35-a6b8-be3430bc66d4'
        }
      ]
    }
  },
  components: { ReservationWidget },
  data() {
    return {
      eventsList
    }
  },
  methods: {
    setSelectedEvent(event, date) {
      this.$eventHub.emit('ReservationWidget.setSelectedEvent', {event, date})
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-wrapper {
  background: $colorAccent4;
}

.hero {
  min-height: calc(100vh - (78px + 90px));
  padding-top: 3vw;
  padding-bottom: 8.5vw;

  background: url('../assets/img/copeprtone-hero.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;

  @media (max-width: 780px) {
    background-image: url('../assets/img/copeprtone-hero-mobile.jpg');
    padding-top: 75px;
    padding-bottom: 74px;
    padding-right: 0;
  }

  .logo-gym {
    display: block;
    width: 100%;
    max-width: 245px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 55px;

    @media (max-width: 780px) {
      max-width: 215px;
      margin-bottom: 35px;
    }
  }

  .hero-title {
    display: none;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2.5vw;
    margin-bottom: 1vw;

    @media (max-width: 780px) {
      margin-top: 35px;
      margin-bottom: 5px;
    }
  }

  .hero-subtitle {
    font-size: 35px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.1;

    @media (max-width: 780px) {
      padding-right: 20px;
      font-size: 18px;

      br {
        display: none;
      }
    }
  }

  .events-wrapper {
    position: relative;
    width: 100%;
    padding-top: 5vw;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: nowrap;

    @media (max-width: 780px) {
      overflow-y: hidden;
      overflow-x: auto;

      padding-top: 40px;
    }

    .event-card {
      width: calc(98.5% / 3);
      background: #fff;

      @media (max-width: 780px) {
        min-width: 70vw;
        margin-right: 20px;
      }

      img {
        display: block;
        width: 100%;
      }

      .content {
        position: relative;
        padding: 2vw;

        @media (max-width: 780px) {
          padding: 20px;
        }
      }

      .event-card-title {
        margin-bottom: 10px;
      }

      .event-card-date {
        margin-bottom: 10px;
        font-size: 14px;
        color: #F28B00;
      }

      .address {
        position: absolute;
        top: 2vw;
        right: 2vw;
        max-width: 50%;
        text-align: left;

        @media (max-width: 780px) {
          top: 20px;
          right: 20px;
        }
      }

      .details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: 10px;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fff;

  padding-top: 15px;
  padding-bottom: 15px;

  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;

  @media (max-width: 680px) {
    flex-direction: column;
  }

  .logo {
    display: block;
    width: 155px;

    @media (max-width: 780px) {
      margin-bottom: 20px;
    }
  }
}
</style>
