<template>
  <div class="page-wrapper">

    <section class="hero fluid-container">
      <div class="hero-container">
        <img class="logo-gym" src="@/assets/img/gym-soleil.svg" alt="Gym au Soleil">
        <h1 class="hero-title big-title">
          Coppertone <sup class="sup">&reg;</sup>
        </h1>
        <h2 class="hero-subtitle medium-title">
          Réservations
        </h2>

        <div class="events-wrapper" v-if="password === 'Gym!2021'">
          <article class="event-card" v-for="event in eventsList" :key="event.id" :class="{ 'is-disabled': event.id === 1 }">
            <img :src="event.img" :alt="event.slug">
            <div class="content">
              <h3 class="event-card-title regular-title"><span v-html="event.title"></span></h3>
              <form>
                <input type="text" name="search" v-model="search" placeholder="Recherche..." >
              </form>
              <div class="details small-title">
                <div v-for="date in event.dates" :key="date.value" class="medium-title">
                  <h3 class="separator-title">{{ date['label-' + $route.meta.lang] }}</h3>
                  <div class="table-view">
                    <table v-if="reservationsFiltered.length" border="1" cellpadding="15">
                      <tr v-for="res in reservationsFiltered.filter(i => i.date === date.slug).sort((a, b) => { if (a.time < b.time) { return -1; } if (a.time > b.time) { return 1; } return 0; })" :key="res.id">
                        <td>
                          {{ res.activity }}
                        </td>
                        <td>
                          {{ res.time }}
                        </td>
                        <td>
                          {{ res.client.email }}
                        </td>
                        <td>
                          {{ res.client.firstname }}
                        </td>
                        <td>
                          {{ res.client.lastname }}
                        </td>
                        <td>
                          {{ res.client.gender }}
                        </td>
                        <td>
                          {{ res.client.lang }}
                        </td>
                        <td>
                          {{ res.client.subscription }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div v-else class="events-wrapper">
          <form action="" class="password-form">
            <input type="text" name="password" v-model="password" placeholder="Mot de passe">
          </form>
        </div>
      </div>
    </section>

    <footer class="footer fluid-container">
      <img class="logo" src="/img/logo-vibrant.webp" alt="Vibfant Marketing">
      <p>Copyright © 2021 VIBRANT Marketing.</p>
    </footer>

    <ReservationWidget />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReservationWidget from '@/components/ReservationWidget'
import { eventsList } from '@/misc/content'
import ReservationsDB from '@/firebase/reservations-db'

export default {
  head() {
    return {
      title: {
        inner: 'Reservations'
      }
    }
  },
  components: { ReservationWidget },
  data() {
    return {
      eventsList,
      reservations: [],
      search: '',
      password: ''
    }
  },
  methods: {
    async fetchReservations() {
      const reservationsDb = new ReservationsDB()
      this.reservations = await reservationsDb.readAll()
    }
  },
  computed: {
    reservationsFiltered() {
      if (!this.search && !this.reservations) {
        return []
      }

      if (this.reservations.length && !this.search) {
        return this.reservations
      }

      return this.reservations.filter(i => i.client.email.includes(this.search) || i.client.firstname.includes(this.search) || i.client.lastname.includes(this.search) || i.activity.includes(this.search))
    },
    ...mapState('app', ['appTitle'])
  },
  mounted() {
    this.fetchReservations()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-wrapper {
  background: $colorAccent4;
}

.hero {
  min-height: calc(100vh - (78px + 90px));
  padding-top: 3vw;
  padding-bottom: 8.5vw;

  background: url('../assets/img/copeprtone-hero.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;

  @media (max-width: 780px) {
    background-image: url('../assets/img/copeprtone-hero-mobile.jpg');
    padding-top: 75px;
    padding-bottom: 74px;
    padding-right: 0;
  }

  .logo-gym {
    display: block;
    width: 100%;
    max-width: 245px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 55px;

    @media (max-width: 780px) {
      max-width: 215px;
      margin-bottom: 35px;
    }
  }

  .hero-title {
    display: none;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2.5vw;
    margin-bottom: 1vw;

    @media (max-width: 780px) {
      margin-top: 35px;
      margin-bottom: 5px;
    }
  }

  .hero-subtitle {
    font-size: 35px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.1;

    @media (max-width: 780px) {
      padding-right: 20px;
      font-size: 18px;

      br {
        display: none;
      }
    }
  }

  .events-wrapper {
    position: relative;
    width: 100%;
    padding-top: 5vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;

    @media (max-width: 780px) {
      overflow-y: hidden;
      overflow-x: auto;

      padding-top: 40px;
    }

    .event-card {
      width: calc(100%);
      max-width: 800px;
      background: #fff;
      margin-bottom: 35px;

      &.is-disabled {
        display: none;
      }

      @media (max-width: 780px) {
        min-width: 70vw;
        margin-right: 20px;
      }

      img {
        display: block;
        width: 100%;
      }

      .content {
        position: relative;
        padding: 2vw;

        @media (max-width: 780px) {
          padding: 20px;
        }
      }

      .event-card-title {
        margin-bottom: 10px;
      }

      .event-card-date {
        margin-bottom: 10px;
        font-size: 14px;
        color: #F28B00;
      }

      .address {
        position: absolute;
        top: 2vw;
        right: 2vw;
        max-width: 50%;
        text-align: left;

        @media (max-width: 780px) {
          top: 20px;
          right: 20px;
        }
      }

      .details {

      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fff;

  padding-top: 15px;
  padding-bottom: 15px;

  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;

  @media (max-width: 680px) {
    flex-direction: column;
  }

  .logo {
    display: block;
    width: 155px;

    @media (max-width: 780px) {
      margin-bottom: 20px;
    }
  }
}

.table-view {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    font-size: 14px;
    text-transform: none;
  }

  table, td {
    border: 1px solid rgba(#000, 0.123)
  }

  td {
    padding: 5px;
    min-width: 120px;
  }
}

.separator-title {
  padding: 15px;
  margin: 5px 0;
  line-height: 1;
  background: rgba(#000, 0.0423);
  border-bottom: 1px solid rgba(#000, 0.0423);
  margin-bottom: 10px;
  margin-top: 45px;
}

.password-form {
  max-width: 500px;
  margin: 0 auto;
}
</style>
