export const globals = {}
export const misc = {}

const EVENTLIBRE = {
  'name': 'Entrainement libre',
  'name-fr': 'Entrainement libre',
  'name-en': 'Individual training',
  'slug': 'entrainement-libre',
  'img': '/img/entrainement-libre.jpg',
  'description': `Entrainement libre : Vous aurez à votre disposition des appareils cardiovasculaires, ainsi que plusieurs items d'entrainement, tel que des poids libres, des barres, des élastiques, et bien plus!`,
  'description-fr': `Vous aurez à votre disposition des appareils cardiovasculaires, ainsi que plusieurs items d'entrainement, tel que des poids libres, des barres, des élastiques, et bien plus!`,
  'description-en': `Enjoy the use of cardio machines as well as different workout equipment such as free weights, bars, elastics and more!`,
  'hoursList': [
    { 'ref': '09:00', 'label-fr': '09:00', 'label-en': '09:00 am', 'places': 12 },
    { 'ref': '10:00', 'label-fr': '10:00', 'label-en': '10:00 am', 'places': 12 },
    { 'ref': '11:00', 'label-fr': '11:00', 'label-en': '11:00 am', 'places': 12 },
    { 'ref': '12:00', 'label-fr': '12:00', 'label-en': '12:00 pm', 'places': 12 },
    { 'ref': '13:00', 'label-fr': '13:00', 'label-en': '01:00 pm', 'places': 12 },
    { 'ref': '14:00', 'label-fr': '14:00', 'label-en': '02:00 pm', 'places': 12 },
    { 'ref': '15:00', 'label-fr': '15:00', 'label-en': '03:00 pm', 'places': 12 },
    { 'ref': '16:00', 'label-fr': '16:00', 'label-en': '04:00 pm', 'places': 12 },
    { 'ref': '17:00', 'label-fr': '17:00', 'label-en': '05:00 pm', 'places': 12 },
    { 'ref': '18:00', 'label-fr': '18:00', 'label-en': '06:00 pm', 'places': 12 }
  ]
}

const EVEREST = {
  'name': 'Everest',
  'name-fr': 'Everest',
  'name-en': 'Everest',
  'slug': 'everest',
  'img': '/img/everest.jpg',
  'description': 'EVEREST est un entraînement semi-privé, avec lequel vous deviendrez plus agile, endurant, rapide, puissant, musclé, résistant, coordonné et flexible.',
  'description-fr': 'EVEREST est un entraînement semi-privé, avec lequel vous deviendrez plus agile, endurant, rapide, puissant, musclé, résistant, coordonné et flexible.',
  'description-en': 'EVEREST is a semi-private cross-training class you’ll gain greater agility, endurance, speed, strength, muscle, resistance, coordination and flexibility.'
}

const BODYDESIGN = {
  'name': 'Body Design',
  'name-fr': 'Body Design',
  'name-en': 'Body Design',
  'slug': 'body-design',
  'img': '/img/body-design.jpg',
  'description': `Body Design est un cours en groupe dans lequel on retrouve des exercices de renforcement musculaire qui travaillent l'ensemble du corps`,
  'description-fr': `Body Design est un cours en groupe dans lequel on retrouve des exercices de renforcement musculaire qui travaillent l'ensemble du corps`,
  'description-en': `Body Design is a group class focused on strengthening exercises that target the entire body.`
}

const CARDIOMILI = {
  'name': 'Cardio Militaire',
  'name-fr': 'Cardio Militaire',
  'name-en': 'Military Cardi',
  'slug': 'cardio-militaire',
  'img': '/img/cardio-militaire.jpg',
  'description': `Le cours CARDIO MILITAIRE s'inspire des entrainements de l'armée organisés sous forme de circuits ou d'intervalles.`,
  'description-fr': `Le cours CARDIO MILITAIRE s'inspire des entrainements de l'armée organisés sous forme de circuits ou d'intervalles.`,
  'description-en': `MILITARY CARDIO uses a system of intervals or circuits inspired by army workout routines.`
}

const VELOSPIN = {
  'name': 'Vélo (spinning)',
  'name-fr': 'Vélo (spinning)',
  'name-en': 'Spinning',
  'slug': 'velo-spinning',
  'img': '/img/spinning.jpg',
  'description': '',
  'description-fr': '',
  'description-en': ''
}

const FESSES = {
  'name': 'Fesses de fer',
  'name-fr': 'Fesses de fer',
  'name-en': 'Fesses de fer',
  'slug': 'fesses-de-fer',
  'img': '/img/adrenaline.jpg',
  'description': 'Musculaire bas du corps',
  'description-fr': 'Musculaire bas du corps',
  'description-en': 'Musculaire bas du corps'
}

const FIGHT = {
  'name': 'FIGHT',
  'name-fr': 'FIGHT',
  'name-en': 'FIGHT',
  'slug': 'fight',
  'img': '/img/fight.jpg',
  'description': `FIGHT est un entrainement en groupe basé sur les techniques de boxe, de Muay Thai et d'arts martiaux mixtes. La musique énergique accompagnée d'effets sonores vous amènera à vous dépasser tout en ayant du plaisir. Fight est un entrainement athlétique qui vous permettra d'améliorer votre capacité cardiovasculaire tout en renforçant vos muscles.`,
  'description-fr': `FIGHT est un entrainement en groupe basé sur les techniques de boxe, de Muay Thai et d'arts martiaux mixtes. La musique énergique accompagnée d'effets sonores vous amènera à vous dépasser tout en ayant du plaisir. Fight est un entrainement athlétique qui vous permettra d'améliorer votre capacité cardiovasculaire tout en renforçant vos muscles.`,
  'description-en': `FIGHT is a group workout based on techniques borrowed from boxing, Muay Thai and mixed martial arts. Dynamic music and sound effects set the soundtrack for fun and personal achievement. An athletic workout to help improve your cardiovascular fitness and muscle strength.`
}

const PILATES = {
  'name': 'Mouvements Pilates',
  'name-fr': 'Mouvements Pilates',
  'name-en': 'Pilates Movements',
  'slug': 'mouvements-pilates',
  'img': '/img/pilates.jpg',
  'description': `Mouvements Pilates propose une série d'exercices sollicitant les muscles profonds qui interviennent dans l'équilibre du corps et le maintien de la colonne vertébrale. Ce cours se pratique principalement au sol et à un rythme lent guidé par la respiration.`,
  'description-fr': `Mouvements Pilates propose une série d'exercices sollicitant les muscles profonds qui interviennent dans l'équilibre du corps et le maintien de la colonne vertébrale. Ce cours se pratique principalement au sol et à un rythme lent guidé par la respiration.`,
  'description-en': `Pilates Movements consists of a series of exercises to work deep muscles that keep the body balanced and the spinal column strong. Done primarily on mats, following the relaxed rhythm of the body’s breath.`
}

const ADRENALINE = {
  'name': 'ADRÉNALINE',
  'name-fr': 'ADRÉNALINE',
  'name-en': 'ADRENALINE',
  'slug': 'adrenaline',
  'img': '/img/adrenaline.jpg',
  'description': `ADRÉNALINE est un entrainement cardiovasculaire et musculaire intense. Il se distingue par la simplicité des mouvements et des enchainements afin que la personne puisse se concentrer sur l'intensité et l'effort.`,
  'description-fr': `ADRÉNALINE est un entrainement cardiovasculaire et musculaire intense. Il se distingue par la simplicité des mouvements et des enchainements afin que la personne puisse se concentrer sur l'intensité et l'effort.`,
  'description-en': `ADRENALINE is an intense cardio and muscle-building workout. Simple movements and sequences allow you to stay focused on the sustained effort required.`
}

const ZUMBA = {
  'name': 'Zumba',
  'name-fr': 'Zumba',
  'name-en': 'Zumba',
  'slug': 'zumba',
  'img': '/img/zumba.jpg',
  'description': `Zumba est un entrainement énergique qui combine les danses latines comme la salsa, le merengue, le reggaeton et la cumbia avec des mouvements simples d'un cours de danse aérobique.  Le tout dans une ambiance de fête!`,
  'description-fr': `Zumba est un entrainement énergique qui combine les danses latines comme la salsa, le merengue, le reggaeton et la cumbia avec des mouvements simples d'un cours de danse aérobique.  Le tout dans une ambiance de fête!`,
  'description-en': `Zumba combines Latin dances such as salsa, merengue, reggaeton and cumbia with simple aerobic dance movements for an energetic workout. Get ready for a festive time!`
}

const YOGA = {
  'name': 'Yoga',
  'name-fr': 'Yoga',
  'name-en': 'Yoga',
  'slug': 'yoga',
  'img': '/img/pilates.jpg',
  'description': ``,
  'description-fr': ``,
  'description-en': ``
}

export const eventsList = [
  {
    'id': 1,
    'slug': 'energie-cardio-boucherville',
    'img': '/img/Energie-Cardio-Boucherville.jpg',
    'title': 'Énergie Cardio <br> Boucherville',
    'address': '1200 Pl. Nobel local c, <br> Boucherville, Quebec J4B 5E2',
    'google': '1200+Pl.+Nobel+local+c,+Boucherville,+Quebec+J4B+5E2',
    'price': 'Gratuit',
    'dates': [
      {
        'label': 'Vendredi 4 Juin',
        'label-fr': 'Vendredi 4 Juin',
        'label-en': 'Friday June 4',
        'value': '4 Juin',
        'value-fr': '4 Juin',
        'value-en': 'June 4',
        'slug': '4-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '09:00', 'label-fr': '09:00-09:45', 'label-en': '09:00-09:45 am', 'places': 8 },
              { 'ref': '14:00', 'label-fr': '14:00-14:45', 'label-en': '02:00-02:45 pm', 'places': 8 },
              { 'ref': '16:00', 'label-fr': '16:00-16:45', 'label-en': '04:00-04:45 pm', 'places': 8 },
              { 'ref': '17:30', 'label-fr': '17:30-18:15', 'label-en': '05:30-06:15 pm', 'places': 8 }
            ]
          },
          {
            ...BODYDESIGN,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00-11:00', 'label-en': '10:00-11:00 am', 'places': 8 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Samedi 5 Juin',
        'label-fr': 'Samedi 5 Juin',
        'label-en': 'Saturday June 5',
        'value': '5 Juin',
        'value-fr': '5 Juin',
        'value-en': 'June 5',
        'slug': '5-juin',
        'activities': [
          {
            ...CARDIOMILI,
            'hoursList': [
              { 'ref': '09:00', 'label-fr': '9:00-10:00', 'label-en': '9:00-10:00 am', 'places': 8 }
            ]
          },
          {
            ...VELOSPIN,
            'hoursList': [
              { 'ref': '10:15', 'label-fr': '10:15-11:15', 'label-en': '10:15-11:15 am', 'places': 8 }
            ]
          },
          {
            ...FIGHT,
            'hoursList': [
              { 'ref': '12:00', 'label-fr': '12:00-13:00', 'label-en': '12:00 pm-01:00 pm', 'places': 8 }
            ]
          },
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '15:00', 'label-fr': '15:00-15:45', 'label-en': '03:00-03:45 pm', 'places': 8 },
              { 'ref': '17:00', 'label-fr': '17:00-17:45', 'label-en': '05:00-05:45 pm', 'places': 8 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Dimanche 6 Juin',
        'label-fr': 'Dimanche 6 Juin',
        'label-en': 'Sunday June 6',
        'value': '6 Juin',
        'value-fr': '6 Juin',
        'value-en': 'June 6',
        'slug': '6-juin',
        'activities': [
          {
            ...CARDIOMILI,
            'hoursList': [
              { 'ref': '09:30', 'label-fr': '9:30-10:30', 'label-en': '9:30-10:30 am', 'places': 8 }
            ]
          },
          {
            ...VELOSPIN,
            'hoursList': [
              { 'ref': '10:45', 'label-fr': '10:45-11:45', 'label-en': '10:45-11:45 am', 'places': 8 }
            ]
          },
          {
            ...PILATES,
            'hoursList': [
              { 'ref': '12:00', 'label-fr': '12:00-13:00', 'label-en': '12:00 pm-13:00 pm', 'places': 8 }
            ]
          },
          {
            ...BODYDESIGN,
            'hoursList': [
              { 'ref': '13:00', 'label-fr': '13:00-14:00', 'label-en': '01:00-02:00 pm', 'places': 8 }
            ]
          },
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '15:00', 'label-fr': '15:00-15:45', 'label-en': '03:00-03:45 pm', 'places': 8 }
            ]
          },
          EVENTLIBRE
        ]
      }
    ]
  },
  {
    'id': 2,
    'slug': 'energie-cardio-terrebonne',
    'img': '/img/Energie-Cardio-Terrebonne.jpg',
    'title': 'Énergie Cardio <br> Terrebonne',
    'address': '2249 Chemin Gascon, <br> Terrebonne, QC J6X 4H3',
    'google': '2249+Chemin+Gascon,+Terrebonne,+QC+J6X+4H3',
    'price': 'Gratuit',
    'dates': [
      {
        'label': 'Vendredi 11 Juin',
        'label-fr': 'Vendredi 11 Juin',
        'label-en': 'Friday June 11',
        'value': '11 Juin',
        'value-fr': '11 Juin',
        'value-en': 'June 11',
        'slug': '11-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '11:00', 'label-fr': '11:00-11:30', 'label-en': '11:00-11:30 am', 'places': 12 },
              { 'ref': '11:30', 'label-fr': '11:30-12:00', 'label-en': '11:30 am-12:00 pm', 'places': 12 },
              { 'ref': '13:00', 'label-fr': '13:00-13:30', 'label-en': '01:00-01:30 pm', 'places': 12 },
              { 'ref': '13:30', 'label-fr': '13:30-14:00', 'label-en': '01:30-02:00 pm', 'places': 12 },
              { 'ref': '18:00', 'label-fr': '18:00-18:30', 'label-en': '06:00-06:30 pm', 'places': 12 },
              { 'ref': '18:30', 'label-fr': '18:30-19:00', 'label-en': '06:30-07:00 pm', 'places': 12 }
            ]
          },
          {
            ...FIGHT,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00-11:00', 'label-en': '10:00-11:00 am', 'places': 25 }
            ]
          },
          {
            ...VELOSPIN,
            'hoursList': [
              { 'ref': '16:00', 'label-fr': '16:00-16:30', 'label-en': '04:00-04:30 pm', 'places': 12 },
              { 'ref': '16:30', 'label-fr': '16:30-17:30', 'label-en': '04:30-05:30 pm', 'places': 12 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Samedi 12 Juin',
        'label-fr': 'Samedi 12 Juin',
        'label-en': 'Saturday June 12',
        'value': '12 Juin',
        'value-fr': '12 Juin',
        'value-en': 'June 12',
        'slug': '12-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '11:00', 'label-fr': '11:00-11:30', 'label-en': '11:00-11:30 am', 'places': 12 },
              { 'ref': '11:30', 'label-fr': '11:30-12:00', 'label-en': '11:30 am-12:00 pm', 'places': 12 },
              { 'ref': '13:00', 'label-fr': '13:00-13:30', 'label-en': '01:00-01:30 pm', 'places': 12 },
              { 'ref': '13:30', 'label-fr': '13:30-14:00', 'label-en': '01:30-02:00 pm', 'places': 12 },
              { 'ref': '18:00', 'label-fr': '18:00-18:30', 'label-en': '06:00-06:30 pm', 'places': 12 },
              { 'ref': '18:30', 'label-fr': '18:30-19:00', 'label-en': '06:30-07:00 pm', 'places': 12 }
            ]
          },
          {
            ...ADRENALINE,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00-10:30', 'label-en': '10:00-10:30 am', 'places': 25 },
              { 'ref': '10:30', 'label-fr': '10:30-11:00', 'label-en': '10:30-11:00 am', 'places': 25 }
            ]
          },
          {
            ...ZUMBA,
            'hoursList': [
              { 'ref': '16:00', 'label-fr': '16:00-16:30', 'label-en': '04:00-04:30 pm', 'places': 25 },
              { 'ref': '16:30', 'label-fr': '16:30-17:00', 'label-en': '04:30-05:00 pm', 'places': 25 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Dimanche 13 Juin',
        'label-fr': 'Dimanche 13 Juin',
        'label-en': 'Sunday June 13',
        'value': '13 Juin',
        'value-fr': '13 Juin',
        'value-en': 'June 13',
        'slug': '13-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '11:00', 'label-fr': '11:00-11:30', 'label-en': '11:00-11:30 am', 'places': 12 },
              { 'ref': '11:30', 'label-fr': '11:30-12:00', 'label-en': '11:30 am-12:00 pm', 'places': 12 },
              { 'ref': '13:00', 'label-fr': '13:00-13:30', 'label-en': '01:00-01:30 pm', 'places': 12 },
              { 'ref': '13:30', 'label-fr': '13:30-14:00', 'label-en': '01:30-02:00 pm', 'places': 12 },
              { 'ref': '18:00', 'label-fr': '18:00-18:30', 'label-en': '06:00-06:30 pm', 'places': 12 },
              { 'ref': '18:30', 'label-fr': '18:30-19:00', 'label-en': '06:30-07:00 pm', 'places': 12 }
            ]
          },
          {
            ...ZUMBA,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00-10:30', 'label-en': '10:00-10:30 am', 'places': 25 },
              { 'ref': '10:30', 'label-fr': '10:30-11:00', 'label-en': '10:30-11:00 am', 'places': 25 }
            ]
          },
          {
            'name': 'LesMills RPM (spinning)',
            'name-fr': 'LesMills RPM (spinning)',
            'name-en': 'LesMills RPM (spinning)',
            'slug': 'lesmilss-rpm',
            'img': '/img/spinning.jpg',
            'description': '',
            'description-fr': '',
            'description-en': '',
            'hoursList': [
              { 'ref': '16:00', 'label-fr': '16:00-16:30', 'label-en': '04:00-04:30 pm', 'places': 8 },
              { 'ref': '16:30', 'label-fr': '16:30-17:00', 'label-en': '04:30-05:00 pm', 'places': 8 }
            ]
          },
          EVENTLIBRE
        ]
      }
    ]
  },
  {
    'id': 3,
    'slug': 'energie-cardio-montreal',
    'img': '/img/Energie-Cardio-Montreal.jpg',
    'title': 'Énergie Cardio <br> Montréal (Canal Lachine)',
    'address': '20 Rue des Seigneurs, <br> Montréal, QC H3K 3K3',
    'google': '20+Rue+des+Seigneurs,+Montréal,+QC+H3K+3K3',
    'price': 'Gratuit',
    'dates': [
      {
        'label': 'Vendredi 18 Juin',
        'label-fr': 'Vendredi 18 Juin',
        'label-en': 'Friday June 18',
        'value': '18 Juin',
        'value-fr': '18 Juin',
        'value-en': 'June 18',
        'slug': '18-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00', 'label-en': '10:00 am', 'places': 12 },
              { 'ref': '12:00', 'label-fr': '12:00', 'label-en': '12:00 pm', 'places': 12 }
            ]
          },
          {
            ...FESSES,
            'hoursList': [
              { 'ref': '11:00', 'label-fr': '11:00', 'label-en': '11:00 am', 'places': 12 }
            ]
          },
          {
            ...PILATES,
            'hoursList': [
              { 'ref': '13:00', 'label-fr': '13:00', 'label-en': '01:00 pm', 'places': 25 }
            ]
          },
          {
            ...ZUMBA,
            'hoursList': [
              { 'ref': '17:30', 'label-fr': '17:30', 'label-en': '05:30 pm', 'places': 25 },
              { 'ref': '18:00', 'label-fr': '18:00', 'label-en': '06:00 pm', 'places': 25 },
              { 'ref': '18:30', 'label-fr': '18:30', 'label-en': '06:30 pm', 'places': 25 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Samedi 19 Juin',
        'label-fr': 'Samedi 19 Juin',
        'label-en': 'Saturday June 19',
        'value': '19 Juin',
        'value-fr': '19 Juin',
        'value-en': 'June 19',
        'slug': '19-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '09:00', 'label-fr': '09:00', 'label-en': '09:00 am', 'places': 12 },
              { 'ref': '11:00', 'label-fr': '11:00', 'label-en': '11:00 am', 'places': 12 },
              { 'ref': '14:00', 'label-fr': '14:00', 'label-en': '02:00 pm', 'places': 12 }
            ]
          },
          {
            ...FIGHT,
            'hoursList': [
              { 'ref': '12:00', 'label-fr': '12:00', 'label-en': '12:00 pm', 'places': 12 }
            ]
          },
          {
            ...YOGA,
            'hoursList': [
              { 'ref': '13:00', 'label-fr': '13:00', 'label-en': '01:00 pm', 'places': 25 }
            ]
          },
          {
            ...VELOSPIN,
            'hoursList': [
              { 'ref': '14:00', 'label-fr': '14:00', 'label-en': '02:00 pm', 'places': 12 }
            ]
          },
          EVENTLIBRE
        ]
      },
      {
        'label': 'Dimanche 20 Juin',
        'label-fr': 'Dimanche 20 Juin',
        'label-en': 'Sunday June 20',
        'value': '20 Juin',
        'value-fr': '20 Juin',
        'value-en': 'June 20',
        'slug': '20-juin',
        'activities': [
          {
            ...EVEREST,
            'hoursList': [
              { 'ref': '11:00', 'label-fr': '11:00', 'label-en': '11:00 am', 'places': 12 },
              { 'ref': '13:00', 'label-fr': '13:00', 'label-en': '01:00 pm', 'places': 12 }
            ]
          },
          {
            ...ZUMBA,
            'hoursList': [
              { 'ref': '10:00', 'label-fr': '10:00', 'label-en': '10:00 am', 'places': 25 },
              { 'ref': '14:00', 'label-fr': '14:00', 'label-en': '02:00 pm', 'places': 25 },
              { 'ref': '14:30', 'label-fr': '14:30', 'label-en': '02:30 pm', 'places': 25 }
            ]
          },
          EVENTLIBRE
        ]
      }
    ]
  }
]
