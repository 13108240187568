const trans = {
  en: {
    "PROFITEZ DU SOLEIL PENDANT VOTRE ENTRAINEMENT": "ENJOY THE SUN WHILE WORKING OUT",
    "GRACE A COPPERTONE": "THANKS TO COPPERTONE",
    "ET ÉNERGIE CARDIO": "AND ÉNERGIE CARDIO",
    "Gym au soleil Coppertone": "Gym au soleil Coppertone",
    "Entraînez-vous et profitez du soleil grâce à Coppertone": "Work out and enjoy the sun with Coppertone",
    "Prix": "Price",
    "Gratuit": "Free",
    "Réservez": "Book",
    "Lundi": "Monday",
    "Mardi": "Tuesday",
    "Mercredi": "Wednesday",
    "Jeudi": "Thursday",
    "Vendredi": "Friday",
    "Samedi": "Saturday",
    "Dimanche": "Sunday",
    "Mai": "May",
    "Juin": "June",
    "Juillet": "July",
    "Août": "August",
    "CHOISISSEZ VOTRE COURS" : "Select your class",
    "Choisissez votre heure" : "Select your time",
    "places disponibles" : "Places availables",
    "Aucune place disponible" : "No more place available",
    "Retour" : "Back",
    "Inscrivez-moi! J’aimerais recevoir des informations exclusives et personnalisées sur les produits, les promotions ou les concours Coppertone, par courriel ou via la publicité en ligne. Si vous ne souhaitez plus recevoir nos courriels, vous pouvez vous désabonner à tout moment. Pour plus d’informations sur notre politique de confidentialité veuillez cliquer" : "Sign me up! I would love to receive exclusive, personalized information about Coppertone products, promotions or sweepstakes via email or online advertising. If you want to stop getting our emails, you can unsubscribe at any time. For more information on our privacy policy please click",
    "ici": "here",
    "Réservez votre place": "Book now",
    "Merci": "Thank you",
    "Vous recevrez sous peu un courriel confirmant votre réservation.<br>Bon entraînement au soleil!": "You’ll soon receive an email with your reservation details.<br>Have a good workout under the sun!",
    "poursuivez sur coppertone.ca": "CONTINUE ON COPPERTONE.CA",
    "COPYRIGHT © 2021 VIBRANT MARKETING.": "COPYRIGHT © 2021 VIBRANT MARKETING.",
    "Prénom": "Firstname",
    "Nom": "Lastname",
    "Courriel": "Email",
    "Sexe": "Gender",
    "Homme": "Men",
    "Femme": "Women",
    "Autre": "Other",
    "Je refuse de répondre": "I refuse to answer",
    "https://www.coppertone.ca/fr-ca/meta-pages/politique-de-confidentialite": "https://www.coppertone.ca/en-ca/meta-pages/privacy-statement"
  }
}

export default trans
