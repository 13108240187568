import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAVV5icm9m77vE6GKtwnWLVBrm6YGy0R-A',
  authDomain: 'coppertone-f40e1.firebaseapp.com',
  projectId: 'coppertone-f40e1',
  storageBucket: 'coppertone-f40e1.appspot.com',
  messagingSenderId: '786089486996',
  appId: '1:786089486996:web:f15d405938422c2862e281',
  measurementId: 'G-KVCXS3QENM'
}

firebase.initializeApp(firebaseConfig)
